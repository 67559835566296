import * as React from "react"
import { ReactElement } from "react"

import { DMTooltip } from "@supernovaio/dm"

import { IconType } from "@supernovaio/icons/types"

import {
  ComboboxItemProps as ComboboxItemPropsPrimitive,
  ComboboxItem as ComboboxItemPrimitive,
  useComboboxContext,
} from "@ariakit/react"

import { cn } from "../../utils/cn"
import { DMIcon } from "../DMIcon"

import { ListboxCheckmarkIcon } from "./utils"

export interface DMListboxItemProps
  extends Omit<
    ComboboxItemPropsPrimitive,
    "disabled" | "focusable" | "tabbable" | "focusOnHover"
  > {
  isDisabled?: boolean
  isFocusable?: boolean
  isTabbable?: boolean
  endTitleSlot?: React.ReactNode
  endSlot?: React.ReactNode
  titleClassName?: string
  innerTitleClassName?: string
  isForceSelected?: boolean
  disabledHint?: string
  title: string
  // For nested listbox items to differentiate between the title and its path
  titlePath?: string
  description?: string
  isCheckmarkHidden?: boolean
  icon?: IconType | ReactElement
}

const DMListboxListItem = React.forwardRef<
  React.ElementRef<typeof ComboboxItemPrimitive>,
  DMListboxItemProps
>(
  (
    {
      value,
      title,
      titleClassName,
      innerTitleClassName,
      titlePath,
      icon,
      isDisabled,
      isForceSelected,
      isFocusable,
      isTabbable,
      endTitleSlot,
      endSlot,
      isCheckmarkHidden,
      disabledHint,
      ...props
    },
    ref
  ) => {
    const combobox = useComboboxContext()
    const isMultiselect = combobox?.useState((state) =>
      Array.isArray(state.selectedValue)
    )
    const isSelected = combobox?.useState((state) => {
      if (!value) return false

      return isMultiselect
        ? state.selectedValue.includes(value)
        : state.selectedValue === value
    })

    const fullTitle = titlePath ? `${titlePath} / ${title}` : title

    // Treshold for showing the full path tooltip
    const showFullPathTooltip = fullTitle.length > 40

    return (
      <ComboboxItemPrimitive
        ref={ref}
        focusOnHover
        disabled={isDisabled}
        focusable={isFocusable}
        hideOnClick={false}
        setValueOnClick={false}
        tabbable={isTabbable}
        {...props}
        value={value}
        id={value}
        className={cn(
          "text-body text-neutral font-semibold",
          "flex flex-row justify-between items-center px-8 py-[6px] mb-nav-items rounded",
          "data-[active-item]:bg-neutral",
          "scroll-my-[48px] scroll-py-[48px]", // for correct behavior with sticky group labels + virtualization keyboard navigation
          isSelected &&
            "!bg-primary-faded data-[active-item]:inner-border inner-border-primary-faded",
          props.className
        )}
      >
        <div
          className={cn(
            "flex flex-1 gap-8 items-center min-w-0 mr-12",
            titleClassName
          )}
        >
          {icon && <DMIcon svg={icon} />}
          <div className="flex items-center flex-1 min-w-0">
            <DMTooltip
              text={fullTitle}
              isHidden={!showFullPathTooltip}
              className="w-full"
            >
              <>
                {titlePath && (
                  <>
                    <span className="text-neutral-faded truncate max-w-[80%]">
                      {titlePath}
                    </span>
                    <span className="text-neutral-faded whitespace-nowrap">
                      &nbsp;/&nbsp;
                    </span>
                  </>
                )}
                <span
                  className={cn(
                    "overflow-ellipsis overflow-hidden max-w-[80%] min-w-[40px] whitespace-nowrap",
                    innerTitleClassName
                  )}
                >
                  {title}
                </span>
              </>
            </DMTooltip>
            <span>{endTitleSlot}</span>
          </div>
        </div>
        {endSlot && <div>{endSlot}</div>}
        {!isCheckmarkHidden && (
          <ListboxCheckmarkIcon
            isSelectable={!isDisabled}
            hideDisabledHint={!disabledHint}
            isSelected={isSelected || isForceSelected}
            disabledHint={disabledHint}
            isMultiselect={isMultiselect}
          />
        )}
      </ComboboxItemPrimitive>
    )
  }
)

DMListboxListItem.displayName = "DMListboxListItem"

export { DMListboxListItem }
